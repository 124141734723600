<template>
  <VDialog
    id="service-worker-update-comp"
    v-model="updateExists"
    persistent
    width="auto"
    max-width="100%"
    style="margin: 1px"
  >
    <VCard flat rounded="lg">
      <VCardActions style="min-height: 0px" class="pa-3">
        <VBtn
          block
          flat
          class="bg-primary"
          color="white"
          prepend-icon="mdi-update"
          @click="refreshApp"
          rounded="lg"
        >
          Update Available
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { DataStore } from "aws-amplify/datastore";
import { getUserStore, getDeviceStore } from "@/store/index";

const storeUser = getUserStore();
const storeDevice = getDeviceStore();
let refreshing = false;
let registration = null;
const updateExists = ref(false);

onMounted(() => {
  try {
    document.addEventListener("swUpdated", updateAvailable, { once: true });

    if (typeof window !== "undefined") {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (refreshing) return;
        refreshing = true;
      });
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
});

function updateAvailable(event) {
  try {
    const customEvent = event;
    registration = customEvent.detail;
    updateExists.value = true;
  } catch (error) {
    console.log(error);
  }
}

async function refreshApp() {
  try {
    storeDevice.storeIsLoading(true);
    updateExists.value = false;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    await DataStore.clear().catch(() => {});
    storeUser.clearUser();
    // await clearAllData()
    storeDevice.storeIsLoading(false);
    if (window) window.location.reload();
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!registration || !registration.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    registration.waiting = registration.waiting.postMessage({
      type: "SKIP_WAITING",
    });
  } catch (error) {
    storeDevice.storeIsLoading(false);
    console.log(error);
  }
}
// --------------------------------------------------------------------------------------
</script>

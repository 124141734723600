<template>
  <div id="client-data-sub"></div>
</template>

<script setup>
import route from "@/router";
import { ref, watchEffect, computed } from "vue";
import { DataStore } from "aws-amplify/datastore";
import { UserData, UserRecipeData } from "@/models";
import { getUserStore, getDeviceStore } from "@/store/index";

const storeUser = getUserStore();
const storeDevice = getDeviceStore();
const isOnline = computed(() => storeDevice.stateIsOnline);

const currentAuthenticatedUser = computed(
  () => storeUser.stateUserData.currentAuthenticatedUser
);
const userData = computed(() => storeUser.stateUserData);
const userDataModel = computed(() => storeUser.stateUserData.userDataModel);
const userSub = computed(() => storeUser.stateUserData.cognitoUserName);

const importRoute = ref(route);
const currentRouteName = computed(() => importRoute.value.currentRoute.name);

let observeQueryUserSubscription = null;
let observeQueryUserRecipeDataSubscription = null;

watchEffect(async () => {
  if (typeof currentAuthenticatedUser.value !== "undefined") {
    await DataStore.start()
      .then(() => {
        subClientData();
      })
      .catch((error) => {
        console.log(error);
      });
  } else if (!isOnline.value && userSub.value !== "") {
    queryClientData();
  } else {
    unsubscribeAll();
  }
});
// });

function unsubscribeAll() {
  if (observeQueryUserSubscription) {
    observeQueryUserSubscription.unsubscribe();
    observeQueryUserSubscription = null;
  }
  if (observeQueryUserRecipeDataSubscription) {
    observeQueryUserRecipeDataSubscription.unsubscribe();
    observeQueryUserRecipeDataSubscription = null;
  }
}

async function subClientData() {
  try {
    if (!observeQueryUserSubscription) {
      observeQueryUserSubscription = DataStore.observeQuery(UserData, (uD) =>
        uD.id.eq(userSub.value)
      ).subscribe(async (snapshot) => {
        const { items, isSynced } = snapshot;
        if (isOnline.value && items.length > 0) {
          if (
            items[0].userImgURL &&
            items[0].userImgURL !== userDataModel.value?.userImgURL
          ) {
            await storeUser.storeUserImg(
              items[0].userImgURL,
              items[0].updatedAt
            );
          }

          storeUser.storeUserDataModel(items[0]);
          storeUser.storeUserID(items[0].id);
        } else if (
          isSynced &&
          items.length === 0 &&
          isOnline.value === true &&
          typeof currentAuthenticatedUser.value !== "undefined" &&
          userSub.value !== "" &&
          currentRouteName.value !== "Auth"
        ) {
          console.log("create userData");
          const userDataModels = await DataStore.query(UserData, (uD) =>
            uD.id.eq(userSub.value)
          );
          console.log("userDataModels", userDataModels);

          if (userDataModels.length === 0) {
            await DataStore.save(
              new UserData({
                id: userSub.value,
                name: userData.value.name,
                username: userData.value.username,
              })
            );
          }
        } else if (!isOnline.value) {
          await storeUser.storeUserImgOffline();

          if (items.length > 0) {
            storeUser.storeUserDataModel(items[0]);
            storeUser.storeUserID(items[0].id);
          }
        }
      });
    }

    if (!observeQueryUserRecipeDataSubscription) {
      observeQueryUserRecipeDataSubscription = DataStore.observeQuery(
        UserRecipeData
      ).subscribe(async (snapshot) => {
        const { items, isSynced } = snapshot;
        if (items.length > 0) {
          // console.log("storeUserRecipeData: ", items[0]);
          storeUser.storeUserRecipeData(items[0]);
        } else if (
          isSynced &&
          items.length === 0 &&
          isOnline.value === true &&
          typeof currentAuthenticatedUser.value !== "undefined" &&
          userSub.value !== "" &&
          currentRouteName.value !== "Auth"
        ) {
          console.log("create UserRecipeData");
          const userRecipeDataModels = await DataStore.query(UserRecipeData);

          if (userRecipeDataModels.length === 0) {
            await DataStore.save(
              new UserRecipeData({
                favoritedRecipes: [],
                sharedRecipes: [],
                viewedRecipes: [],
              })
            );
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

async function queryClientData() {
  try {
    const queriedUserData = await DataStore.query(UserData, (uD) =>
      uD.id.eq(userSub.value)
    );

    await storeUser.storeUserImgOffline();

    if (queriedUserData.length > 0) {
      storeUser.storeUserDataModel(queriedUserData[0]);
      storeUser.storeUserID(queriedUserData[0].id);
    }

    const queriedUserRecipeData = await DataStore.query(UserRecipeData);

    if (queriedUserRecipeData.length > 0) {
      storeUser.storeUserRecipeData(queriedUserRecipeData[0]);
    }
  } catch (error) {
    console.log(error);
  }
}
</script>

<template>
  <VDialog id="loading-dialog-comp" persistent>
    <VRow justify="center">
      <!-- <VBtn flat class="bg-transparent" color="primary" loading></VBtn> -->
      <div class="v-progress-circular v-progress-circular--indeterminate v-progress-circular--visible v-theme--light"
        role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width: 3rem; height: 3rem;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.80952380952381 43.80952380952381"
          style="color: rgb(56, 142, 60); transform: rotate(calc(-90deg)); background-color: rgba(0, 0, 0, 0);">
          <circle class="v-progress-circular__underlay" fill="transparent" cx="50%" cy="50%" r="20"
            stroke-width="3.8095238095238093" stroke-dasharray="125.66370614359172" stroke-dashoffset="0"
            style="color: rgba(0,0,0,0);"></circle>
          <circle class="v-progress-circular__overlay" fill="transparent" cx="50%" cy="50%" r="20"
            stroke-width="3.8095238095238093" stroke-dasharray="125.66370614359172"
            stroke-dashoffset="125.66370614359172px"></circle>
        </svg>
      </div>
    </VRow>
  </VDialog>
</template>
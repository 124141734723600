import { createApp } from "vue";
import App from "@/App.vue";
import vuetify from "@/plugins/vuetify.js";
import { loadFonts } from "./plugins/webfontloader";
import { createPinia } from "pinia";
import "./registerServiceWorker";
import { Amplify } from "aws-amplify";
import { AuthModeStrategyType, DataStore } from "aws-amplify/datastore";
// import awsExports from "@/aws-exports.js";
import amplifyconfig from "@/amplifyconfiguration.json";
import router from "@/router/index.js";
import AmplifyVue from "@aws-amplify/ui-vue";

await DataStore.stop();

Amplify.configure(amplifyconfig);

DataStore.configure({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
});

// await DataStore.start()

loadFonts();

const app = createApp(App)
  .use(router)
  .use(createPinia())
  .use(vuetify)
  .use(AmplifyVue);
export const appInstance = app.mount("#app");

<template>
  <div id="user-layout">
    <VAppBar
      ref="appBar"
      app
      flat
      rounded="lg"
      color="primary"
      fixed
      class="rounded-t-0"
    >
      <VAppBarNavIcon class="white--text" @click="drawer = !drawer" />
      <VChip class="pa-0">
        <VBtn color="white" size="x-small" icon @click="goBack">
          <VIcon size="x-large">mdi-arrow-left</VIcon>
        </VBtn>
        <VBtn color="white" size="x-small" icon @click="goForward">
          <VIcon size="x-large">mdi-arrow-right</VIcon>
        </VBtn>
      </VChip>
      <VToolbarTitle class="white--text ml-3">{{
        currentRouteName
      }}</VToolbarTitle>
      <VBtn
        to="/"
        variant="text"
        height="40"
        width="40"
        min-width="40"
        :active="false"
      >
        <VImg
          :src="require('@/assets/img-other/logo-white.svg')"
          height="30"
          width="30"
        />
      </VBtn>
    </VAppBar>

    <VNavigationDrawer v-model="drawer" app class="pa-3">
      <template v-slot:prepend>
        <VListItem
          id="nav-drawer-user"
          :title="userData.name !== '' ? userData.name : 'Login'"
          :subtitle="
            userData.username !== '' ? userData.username : 'Or Sign Up Here!'
          "
          class="py-2 px-0"
          @click="$router.push({ path: '/Profile' })"
          rounded="lg"
        >
          <template v-slot:prepend>
            <VAvatar size="50" class="mr-3">
              <img
                :src="
                  userData.userImg
                    ? userData.userImg
                    : require('@/assets/img-other/user-profile-img-blank.png')
                "
                alt="avatar"
                height="50"
                width="50"
                cover
              />
            </VAvatar>
          </template>

          <template v-slot:append>
            <VBtn
              size="small"
              variant="text"
              icon
              :active="currentRouteName === 'Profile'"
              to="/Profile"
            >
              <VIcon
                size="x-large"
                :color="currentRouteName === 'Profile' ? 'primary' : ''"
                >{{
                  currentRouteName === "Profile"
                    ? "mdi-menu-right"
                    : "mdi-menu-down"
                }}</VIcon
              >
            </VBtn>
          </template>
        </VListItem>
      </template>

      <v-divider class="mt-3"></v-divider>

      <div style="max-height: -webkit-fill-available; overflow-y: auto">
        <VList :lines="false" nav app class="app-list">
          <v-list-item
            v-for="(item, i) in sidebarItems"
            :active="item.title === currentRouteName"
            :key="i"
            :to="item.to"
            color="primary"
            rounded="lg"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            :disabled="executingSignInOut || !isOnline"
            color="primary"
            @click="executeSignInOut"
            rounded="lg"
            :prepend-icon="
              userData.currentAuthenticatedUser ? 'mdi-logout' : 'mdi-login'
            "
          >
            <v-list-item-title>{{
              userData.currentAuthenticatedUser ? "Sign Out" : "Sign In"
            }}</v-list-item-title>
          </v-list-item>
        </VList>
      </div>

      <div
        class="text-center"
        style="position: absolute; bottom: 12px; width: calc(100% - 24px)"
      >
        <div>
          <VChip variant="flat" class="bg-primary">{{ appVersion }}</VChip>
        </div>
      </div>
    </VNavigationDrawer>

    <v-main>
      <RouterView />
    </v-main>

    <VBottomNavigation
      v-if="currentRouteName !== 'Auth'"
      id="botton-navigation"
      app
      flat
      rounded="lg"
      fixed
      grow
      class="rounded-b-0"
      bg-color="primary"
    >
      <VBtn v-for="(btn, id) in bottomNavBtns" :key="id" :to="btn.to">
        <VIcon>{{ btn.icon }}</VIcon>
        {{ btn.text }}
      </VBtn>
    </VBottomNavigation>
    <LoadingDialog v-model="loadingDialog" />
  </div>
</template>

<script lang="ts" setup>
import route from "@/router";
import { ref, watchEffect, computed } from "vue";
import { DataStore } from "aws-amplify/datastore";
import { signOut } from "aws-amplify/auth";
import { useRouter } from "vue-router";
import { getUserStore, getDeviceStore } from "@/store/index";
import LoadingDialog from "@/components/components-global/LoadingDialog.vue";
import vueAppFunctionsVariables from "@/components/components-varfuns/vueAppFunctionsVariables.vue";
import { clearAllData } from "@/components/components-varfuns/vueDynamoDBFunctons";
// import _ from 'lodash'

const appVersion = vueAppFunctionsVariables.appVersion;
const storeUser = getUserStore();
const storeDevice = getDeviceStore();
const isOnline = computed(() => storeDevice.stateIsOnline);
const isLoading = computed(() => storeDevice.stateIsLoading);
const userData = computed(() => storeUser.stateUserData);
const bottomNavItemsArray = vueAppFunctionsVariables.bottomNavItemsArray;
const sidebarItems = [
  {
    title: "Home",
    to: "/",
    icon: "mdi-home",
  },
  {
    title: "Recipes",
    to: "/Recipes",
    icon: "mdi-book",
  },
  // {
  //     title: 'Shopping List',
  //     to: '/shoppinglist',
  //     icon: 'mdi-shopping',
  // },
  // {
  //     title: 'Chat',
  //     to: '/chat',
  //     icon: 'mdi-forum',
  // },
  {
    title: "Profile",
    to: "/Profile",
    icon: "mdi-account",
  },
  {
    title: "Settings",
    to: "/Settings",
    icon: "mdi-cog",
  },
  {
    title: "About",
    to: "/About",
    icon: "mdi-information",
  },
];
const bottomNavBtns = ref();

const loadingDialog = ref(false);
const drawer = ref(false);
const currentRouteName = ref("");
const router = useRouter();
const importRoute = ref(route);
const executingSignInOut = ref(false);

watchEffect(() => {
  if (isLoading.value && !loadingDialog.value) {
    loadingDialog.value = true;
  } else if (!isLoading.value && loadingDialog.value) {
    loadingDialog.value = false;
  }

  if (typeof importRoute.value.currentRoute.name === "string") {
    if (
      importRoute.value.currentRoute.name &&
      importRoute.value.currentRoute.name !== currentRouteName.value
    ) {
      currentRouteName.value = importRoute.value.currentRoute.name;
      changeBottomNavBtns(importRoute.value.currentRoute.name);
    }
  }
});

function goForward() {
  if (importRoute.value.options.history.state.forward) {
    importRoute.value.forward(); // Go back in the browser history
  }
}

function goBack() {
  if (importRoute.value.options.history.state.back) {
    importRoute.value.back(); // Go back in the browser history
    // importRoute.value.go(-1); // Go back in the browser history
  } else {
    importRoute.value.push({ path: "/" }); // Navigate to the home page if no history to go back
  }
}

async function executeSignInOut() {
  try {
    executingSignInOut.value = true;

    if (typeof userData.value.currentAuthenticatedUser !== "undefined") {
      await handleSignOut();
    } else {
      await handleSignIn();
    }

    executingSignInOut.value = false;
  } catch (error) {
    console.log(error);
  }
}

function changeBottomNavBtns(newRouteName: string) {
  if (typeof newRouteName === "undefined") {
    bottomNavBtns.value = [];
  } else if (newRouteName === "Home") {
    bottomNavBtns.value = bottomNavItemsArray.home;
  } else if (newRouteName === "RecipeURL") {
    bottomNavBtns.value = bottomNavItemsArray.recipeURL;
  } else if (
    newRouteName === "Recipes" ||
    newRouteName === "Favorite Recipes"
  ) {
    bottomNavBtns.value = bottomNavItemsArray.recipes;
  } else bottomNavBtns.value = bottomNavItemsArray.other;
}

async function handleSignOut() {
  try {
    loadingDialog.value = true;

    await signOut();
    await DataStore.clear();
    storeUser.clearUser();
    await clearAllData();
    localStorage.clear();

    loadingDialog.value = false;

    router.push({ path: "/" }).then(() => {
      if (window) window.location.reload();
    });
  } catch (error) {
    loadingDialog.value = false;
    console.log("error signing out: ", error);
  }
}

async function handleSignIn() {
  try {
    router.push({ path: "/Auth" });
  } catch (error) {
    console.log("error signing out: ", error);
  }
}
</script>
<style>
.v-navigation-drawer__content {
  margin-bottom: 48px;
  overflow: hidden !important;
}

.app-list {
  padding: 12px 0 !important;
}

#user-layout {
  display: flex;
  flex-grow: 1;
}

#loadingOverlay > span.v-btn__loader > div {
  height: 50px !important;
  width: 50px !important;
}
</style>

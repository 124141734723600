// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#388E3C',
          secondary: '#1B5E20',
        }
      },
      dark: {
        dark: true,
        colors: {
          primary: '#388E3C',
          secondary: '#1B5E20',
        }
      },
    },
  },
})
import { createRouter, createWebHistory } from "vue-router";
import { computed } from "vue";
import { fetchUserAttributes } from "aws-amplify/auth";
import vueAppFunctionsVariables from "@/components/components-varfuns/vueAppFunctionsVariables.vue";
import UserLayout from "@/layouts/UserLayout.vue";
import { getUserStore } from "@/store/index";

let userDataValid = false;
let storeUser = null;
let currentAuthenticatedUser = undefined;

const routes = [
  {
    path: "/",
    component: UserLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/pages/HomePage.vue"),
      },
      {
        path: "About",
        name: "About",
        component: () => import("@/pages/AboutPage.vue"),
      },
      {
        path: "Recipe/:recipeId",
        name: "RecipeURL",
        component: () => import("@/views/RecipeURLView.vue"),
      },
      {
        path: "Auth",
        name: "Auth",
        component: () => import("@/components/core/AmplifyAuth.vue"),
      },
      {
        path: "Profile",
        name: "Profile",
        component: () => import("@/pages/ProfilePage.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "Recipes",
        name: "Recipes",
        component: () => import("@/pages/RecipesPage.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "Recipes/Favorites",
        name: "Favorite Recipes",
        component: () => import("@/pages/FavoriteRecipesPage.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "YourRecipes",
        name: "Your Recipes",
        component: () => import("@/pages/YourRecipesPage.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "Chat",
        name: "Chat",
        component: () => import("@/pages/ChatPage.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "SmartSearch",
        name: "Smart Search",
        component: () => import("@/pages/SmartSearchPage.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "ShoppingList",
        name: "Shopping List",
        component: () => import("@/pages/ShoppingListPage.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "Settings",
        name: "Settings",
        component: () => import("@/pages/SettingsPage.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: ":pathMatch(.*)*",
        name: "Page Not Found",
        component: () => import("@/pages/PageNotFound.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  async scrollBehavior() {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (!storeUser) {
    storeUser = getUserStore();
    currentAuthenticatedUser = computed(
      () => storeUser.stateUserData.currentAuthenticatedUser
    );
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (typeof currentAuthenticatedUser.value === "undefined") {
    await fetchUserAttributes()
      .then((authenticatedUser) => {
        userDataValid = true;
        storeUser.storeUser(authenticatedUser);
        storeUser.storeCurrentAuthenticatedUser(
          authenticatedUser ? authenticatedUser : undefined
        );
        vueAppFunctionsVariables.storeCognitoUserData(authenticatedUser);
      })
      .catch(() => {});
  }

  if (userDataValid === false) {
    const userData = vueAppFunctionsVariables.getCognitoUserData();

    if (typeof userData !== "undefined") {
      userDataValid = true;
      storeUser.storeUser(userData);
    }
  }

  if (!requiresAuth || (requiresAuth && userDataValid === true)) {
    next();
  } else {
    next({
      path: "/Auth",
      query: { redirect: to.fullPath },
    });
  }
});

export default router;

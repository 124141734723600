import { defineStore } from "pinia";
// import { ref, reactive } from "vue";
import { downloadData } from "aws-amplify/storage";
import vueAppFunctionsVariables from "@/components/components-varfuns/vueAppFunctionsVariables.vue";
import {
  storeBase64String,
  getBase64StringByIdOrEmail,
  getUpdatedAtImgStore,
  deleteBase64StringsByRecipeIdOrEmail,
} from "@/components/components-varfuns/vueDynamoDBFunctons";
import _ from "lodash";

export const getUserStore = defineStore("user", {
  state: () => ({
    stateUserData: {
      userID: "",
      userImgKey: "",
      userImg: "",
      username: "",
      name: "",
      email: "",
      cognitoUserName: "",
      currentAuthenticatedUser: undefined,
      userDataModel: null,
    },
    stateUserRecipeData: {
      favoritedRecipes: [],
      sharedRecipes: [],
      viewedRecipes: [],
      syncedUserRecipeData: false,
      userRecipeDataModel: null,
    },
  }),
  getters: {},
  actions: {
    storeUserRecipeData(userRecipeData) {
      this.stateUserRecipeData.userRecipeDataModel = userRecipeData;

      if (userRecipeData.favoritedRecipes?.length > 0)
        this.stateUserRecipeData.favoritedRecipes =
          userRecipeData.favoritedRecipes;
      if (userRecipeData.sharedRecipes?.length > 0)
        this.stateUserRecipeData.sharedRecipes = userRecipeData.sharedRecipes;
      if (userRecipeData.viewedRecipes?.length > 0)
        this.stateUserRecipeData.viewedRecipes = userRecipeData.viewedRecipes;

      this.stateUserRecipeData.syncedUserRecipeData = true;
    },

    clearUser() {
      try {
        this.stateUserRecipeData.syncedUserRecipeData = false;

        this.stateUserData.currentAuthenticatedUser = undefined;
        this.stateUserData.userID = "";
        this.stateUserData.email = "";
        this.stateUserData.userImgKey = "";
        this.stateUserData.userImg = "";
        this.stateUserData.username = "";
        this.stateUserData.name = "";
        this.stateUserData.cognitoUserName = "";
        this.stateUserData.userDataModel = null;

        this.stateUserRecipeData.favoritedRecipes = [];
        this.stateUserRecipeData.sharedRecipes = [];
        this.stateUserRecipeData.viewedRecipes = [];
        this.stateUserRecipeData.userRecipeDataModel = null;
      } catch (error) {
        console.log(error);
      }
    },
    async storeUserImg(userImgUrl, userUpdatedAt) {
      try {
        if (this.stateUserData.userImgKey !== userImgUrl) {
          const updatedAtImgStore = await getUpdatedAtImgStore(
            "user",
            this.stateUserData.email
          );
          if (updatedAtImgStore) {
            if (
              this.stateUserData.userImg === "" &&
              userUpdatedAt === updatedAtImgStore
            ) {
              const base64String = await getBase64StringByIdOrEmail(
                "user",
                this.stateUserData.email
              );
              const image = base64String ? JSON.parse(base64String) : undefined;
              const dCrypt = image
                ? await vueAppFunctionsVariables.decryptString(image)
                : "";
              this.stateUserData.userImg = dCrypt;
            } else if (userUpdatedAt !== updatedAtImgStore) {
              // const imageDownload = await Storage.get(userImgUrl, {
              //   level: "private",
              //   download: true,
              // });
              // const imageDownload = await getUrl({
              //   key: userImgUrl,
              //   options: {
              //     accessLevel: "private", // can be 'private', 'protected', or 'guest' but defaults to `guest`
              //     validateObjectExistence: false, // defaults to false
              //     expiresIn: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
              //     useAccelerateEndpoint: true, // Whether to use accelerate endpoint.
              //   },
              // });

              const {
                body,
                // , eTag
              } = await downloadData({
                key: userImgUrl,
                options: {
                  accessLevel: "private", // access level of the file being downloaded
                },
              }).result;

              if (body) {
                await deleteBase64StringsByRecipeIdOrEmail(
                  "user",
                  this.stateUserData.email
                );
                const r = await vueAppFunctionsVariables.convertImageToString({
                  blob: body,
                });
                const eCrypt = await vueAppFunctionsVariables.encryptString(r);
                const didStoreUserImg = await storeBase64String(
                  "user",
                  this.stateUserData.email,
                  JSON.stringify(eCrypt),
                  userUpdatedAt
                );
                this.stateUserData.userImg = r;
                if (!didStoreUserImg) {
                  console.log("Storage Full");
                }
              }
            }
          } else {
            this.stateUserData.userImgKey = userImgUrl;

            // const imageDownload = await Storage.get(userImgUrl, {
            //   level: "private",
            //   download: true,
            // });
            // const imageDownload = await getUrl({
            //   key: userImgUrl,
            //   options: {
            //     accessLevel: "private", // can be 'private', 'protected', or 'guest' but defaults to `guest`
            //     validateObjectExistence: false, // defaults to false
            //     expiresIn: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
            //     useAccelerateEndpoint: true, // Whether to use accelerate endpoint.
            //   },
            // });

            const {
              body,
              // , eTag
            } = await downloadData({
              key: userImgUrl,
              options: {
                accessLevel: "private", // access level of the file being downloaded
              },
            }).result;

            let imageString;
            let didStoreUserImg;
            if (body) {
              imageString = await vueAppFunctionsVariables.convertImageToString(
                { blob: body }
              );
              const eCrypt = await vueAppFunctionsVariables.encryptString(
                imageString
              );
              didStoreUserImg = await storeBase64String(
                "user",
                this.stateUserData.email,
                JSON.stringify(eCrypt),
                userUpdatedAt
              );
            }
            if (!didStoreUserImg) {
              console.log(didStoreUserImg);
              console.log("Storage Full");
            }
            this.stateUserData.userImg = imageString;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async storeUserImgOffline() {
      try {
        const base64String = await getBase64StringByIdOrEmail(
          "user",
          this.stateUserData.email
        );

        if (base64String) {
          let imageString;
          try {
            imageString = await JSON.parse(base64String);
          } catch (jsonError) {
            console.error("Error parsing base64String as JSON", jsonError);
          }
          let dCrypt;
          try {
            dCrypt = imageString
              ? await vueAppFunctionsVariables.decryptString(imageString)
              : "";
          } catch (decryptError) {
            console.error("Error decrypting the image", decryptError);
            throw decryptError;
          }
          this.stateUserData.userImg = dCrypt;
        } else {
          console.log("No UserImgStored retrieved");
        }
      } catch (error) {
        console.error("Error in storeUserImgOffline", error);
      }
    },
    storeUser(userData) {
      if (userData.email) {
        this.stateUserData.email = userData.email;
      }
      if (userData.preferred_username) {
        this.stateUserData.username = userData.preferred_username;
      }
      if (userData.sub) {
        this.stateUserData.cognitoUserName = userData.sub;
      }
      if (userData.name) {
        this.stateUserData.name = userData.name;
      }
    },
    storeCurrentAuthenticatedUser(userData) {
      this.stateUserData.currentAuthenticatedUser = userData
        ? userData
        : undefined;
    },
    storeUserID(userID) {
      this.stateUserData.userID = userID;
    },
    storeUserDataModel(dataModel) {
      this.stateUserData.userDataModel = dataModel;
    },
  },
});
export const getDeviceStore = defineStore("deviceStore", {
  state: () => ({
    stateIsOnline: false,
    stateIsLoading: false,
  }),
  getters: {},
  actions: {
    storeIsOnline(boolValue) {
      this.stateIsOnline = boolValue;
    },
    storeIsLoading(boolValue) {
      this.stateIsLoading = boolValue;
    },
  },
});
export const getRecipeImagesStore = defineStore("recipesImagesStore", {
  state: () => ({
    stateStoredRecipeImages: [
      {
        id: "",
        imageUrl: "",
      },
    ],
  }),
  getters: {},
  actions: {
    storeRecipeImg(recipeImgObj) {
      try {
        this.stateStoredRecipeImages.push(recipeImgObj);
      } catch (error) {
        console.log(error);
      }
    },
    removeRecipeImg(recipeId) {
      try {
        this.stateStoredRecipeImages = _.remove(this.stateStoredRecipeImages, {
          id: recipeId,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
});

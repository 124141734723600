// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { UserData, UserRecipeData, Recipe } = initSchema(schema);

export {
  UserData,
  UserRecipeData,
  Recipe
};
<template>
  <div id="network-status-comp"></div>
</template>
<script setup>
import {
  onMounted,
  onBeforeUnmount,
  watchEffect,
  computed,
  nextTick,
} from "vue";
import { getDeviceStore, getUserStore } from "@/store/index";
import { fetchUserAttributes } from "aws-amplify/auth";
import vueAppFunctionsVariables from "@/components/components-varfuns/vueAppFunctionsVariables.vue";

let initiallyLoaded = false;
const storeDevice = getDeviceStore();
const storeUser = getUserStore();

const isOnline = computed(() => storeDevice.stateIsOnline);
const currentAuthenticatedUser = computed(
  () => storeUser.stateUserData.currentAuthenticatedUser
);

let previouslyOnline = false;
// Watch for changes in isOnline and isOffline
watchEffect(async () => {
  if (navigator.onLine && !isOnline.value) {
    if (!previouslyOnline) {
      previouslyOnline = true;
    }

    if (
      initiallyLoaded &&
      typeof currentAuthenticatedUser.value === "undefined"
    ) {
      await fetchUserAttributes()
        .then((authenticatedUser) => {
          storeUser.storeUser(authenticatedUser);
          storeUser.storeCurrentAuthenticatedUser(
            authenticatedUser ? authenticatedUser : undefined
          );
          vueAppFunctionsVariables.storeCognitoUserData(authenticatedUser);
        })
        .catch(() => {});
    }

    storeDevice.storeIsOnline(true);
  } else if (!navigator.onLine && isOnline.value) {
    storeDevice.storeIsOnline(false); // Update this based on how you have defined storeDevice
  }
});

onMounted(async () => {
  try {
    if (window) {
      // Initial state
      storeDevice.storeIsOnline(navigator.onLine); // Update the initial status

      // Attach event listeners
      window.addEventListener("online", onlineHandler);
      window.addEventListener("offline", offlineHandler);
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }

  await nextTick();

  initiallyLoaded = true;
});

onBeforeUnmount(() => {
  if (window) {
    window.removeEventListener("online", onlineHandler);
    window.removeEventListener("offline", offlineHandler);
  }
});

async function onlineHandler() {
  if (!isOnline.value) {
    if (!previouslyOnline) {
      previouslyOnline = true;
    }

    if (
      initiallyLoaded &&
      typeof currentAuthenticatedUser.value === "undefined"
    ) {
      await fetchUserAttributes()
        .then((authenticatedUser) => {
          storeUser.storeUser(authenticatedUser);
          storeUser.storeCurrentAuthenticatedUser(
            authenticatedUser ? authenticatedUser : undefined
          );
          vueAppFunctionsVariables.storeCognitoUserData(authenticatedUser);
        })
        .catch(() => {});
    }

    storeDevice.storeIsOnline(true);
  }
}

function offlineHandler() {
  if (isOnline.value) {
    storeDevice.storeIsOnline(false);
  }
}
</script>

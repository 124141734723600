<template>
  <VApp id="app" v-if="appLoaded">
    <!-- <component :is="route.meta.layout"> -->
    <RouterView />
    <!-- </component> -->
    <ServiceWorkerUpdate />
    <NetworkStatus />
    <ClientData />
  </VApp>
</template>

<script lang="ts" setup>
import { ref } from "vue";
// import { useRoute } from "vue-router";
import NetworkStatus from "@/components/core/NetworkStatus.vue";
import ServiceWorkerUpdate from "@/components/core/ServiceWorkerUpdate.vue";
import ClientData from "@/components/core/ClientData.vue";

// --------------------------------------------------------------------------------------

// const route = useRoute();
const appLoaded = ref(true);

// --------------------------------------------------------------------------------------
</script>

<style>
#app {
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #388e3c;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

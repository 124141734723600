<script>
import _ from "lodash";
export default {
  name: "vueAppFunctionsVariables",
  appVersion: "V 1.0.2",
  bottomNavItemsArray: {
    home: [
      {
        text: "Home",
        to: "/",
        icon: "mdi-home",
      },
      {
        text: "Recipes",
        to: "/Recipes",
        icon: "mdi-book",
      },
      {
        text: "Favorite",
        to: "/Recipes/Favorites",
        icon: "mdi-heart",
      },
      {
        text: "Profile",
        to: "/Profile",
        icon: "mdi-account",
      },
    ],
    recipes: [
      //     {
      //     text: 'All Recipes',
      //     to: '/',
      //     icon: 'mdi-home'
      // },
      {
        text: "Recipes",
        to: "/Recipes",
        icon: "mdi-book",
      },
      {
        text: "Favorites",
        to: "/Recipes/Favorites",
        icon: "mdi-heart",
      },
    ],
    recipeURL: [
      {
        text: "More Recipes",
        to: "/Recipes",
        icon: "mdi-book",
      },
    ],
    other: [
      {
        text: "Home",
        to: "/",
        icon: "mdi-home",
      },
    ],
  },
  sidebarItems: [
    {
      title: "Home",
      to: "/",
      icon: "mdi-home",
    },
    {
      title: "Recipes",
      to: "/Recipes",
      icon: "mdi-book",
    },
    // {
    //     title: 'Shopping List',
    //     to: '/shoppinglist',
    //     icon: 'mdi-shopping',
    // },
    // {
    //     title: 'Chat',
    //     to: '/chat',
    //     icon: 'mdi-forum',
    // },
    {
      title: "Profile",
      to: "/Profile",
      icon: "mdi-account",
    },
    {
      title: "Settings",
      to: "/Settings",
      icon: "mdi-cog",
    },
    {
      title: "About",
      to: "/About",
      icon: "mdi-information",
    },
    {
      title: "Sign Out",
      // to: '',
      click: "signOut",
      icon: "mdi-logout",
    },
  ],
  encryptURLString(str) {
    const encryptedCharacters = [];

    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);

      // Apply transformations for stronger encryption
      const encryptedChar = char + 123; // Example transformation
      encryptedCharacters.push(String.fromCharCode(encryptedChar));

      // Place a random character after each encrypted character
      const randomChar = String.fromCharCode(
        Math.floor(Math.random() * 94) + 32
      );
      encryptedCharacters.push(randomChar);
    }

    return encodeURIComponent(encryptedCharacters.join("")); // URL-friendly encoding
  },

  decryptURLString(encryptedStr) {
    const decodedEncryptedStr = decodeURIComponent(encryptedStr);
    const encryptedCharacters = decodedEncryptedStr.split("");
    const decryptedCharacters = [];

    for (let i = 0; i < encryptedCharacters.length; i += 2) {
      const encryptedChar = encryptedCharacters[i].charCodeAt(0);

      // Reverse transformations for decryption
      const decryptedChar = encryptedChar - 123; // Example transformation
      decryptedCharacters.push(String.fromCharCode(decryptedChar));
    }

    return decryptedCharacters.join("");
  },

  encryptString(str) {
    const characters = str.split("");
    const encryptedCharacters = [];

    for (let i = 0; i < characters.length; i++) {
      const char = characters[i];
      encryptedCharacters.push(char);

      // Place a random character after each character
      const randomChar = String.fromCharCode(
        Math.floor(Math.random() * 94) + 32
      );
      encryptedCharacters.push(randomChar);
    }

    return encryptedCharacters.join("");
  },
  decryptString(encryptedStr) {
    const encryptedCharacters = encryptedStr.split("");
    const decryptedCharacters = [];

    for (let i = 0; i < encryptedCharacters.length; i += 2) {
      const char = encryptedCharacters[i];
      decryptedCharacters.push(char);
    }

    return decryptedCharacters.join("");
  },
  async convertImageToString(image) {
    try {
      const imageLocal = image ? _.cloneDeep(image) : undefined;
      if (imageLocal) {
        const reader = new FileReader();

        const promiseLocal = new Promise((resolve) => {
          reader.onload = () => {
            const imageString = reader.result;
            resolve(imageString);
          };

          reader.onerror = () => {
            resolve(undefined);
          };

          reader.readAsDataURL(imageLocal.blob);
        });
        return promiseLocal;
      } else return undefined;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  },

  storeCognitoUserData(userData) {
    try {
      const value = localStorage.getItem("CognitoUserData");

      if (_.isEqual(JSON.parse(value), userData)) return;

      const jsonData = JSON.stringify(userData);
      localStorage.setItem("CognitoUserData", jsonData);
    } catch (error) {
      console.error("Error saving data to localStorage:", error);
    }
  },

  getCognitoUserData() {
    const value = localStorage.getItem("CognitoUserData");

    return value ? JSON.parse(value) : undefined;
  },
};
</script>

// Helper Functions -------------------
async function getDatabase(storeType: string, databaseName: string): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
        const request = window.indexedDB.open(databaseName);

        request.addEventListener('upgradeneeded', (event: IDBVersionChangeEvent) => {
            const db = (event.target as IDBOpenDBRequest).result as IDBDatabase;
            const objectStore = db.createObjectStore(storeType === 'recipe' ? 'recipeImages' : 'userData', { keyPath: 'id' });
            // Create the index if it doesn't exist
            if (!objectStore.indexNames.contains('idIndex')) {
                objectStore.createIndex('idIndex', 'id', { unique: true });
            }
        });

        request.addEventListener('success', (event: Event) => {
            resolve((event.target as IDBOpenDBRequest).result as IDBDatabase);
        });

        request.addEventListener('error', (event: Event) => {
            reject((event.target as IDBOpenDBRequest).error);
        });
    });
}

async function isDatabaseFull(val: number): Promise<boolean> {
    const quotaEstimate = await navigator.storage.estimate();
    const usage = quotaEstimate.usage ?? 0;
    const quota = quotaEstimate.quota ?? Number.POSITIVE_INFINITY;
    return (usage + val) >= quota;
}

// Main Functions -------------------
export async function storeBase64String(storeType: string, recipeIdOrUserEmail: string, base64String: string, updatedAt: string) {
    try {
        const db = await getDatabase(storeType, storeType === 'recipe' ? 'recipe-client-database' : 'user-client-database');
        const isDataBaseFullConst = await isDatabaseFull(storeType === 'recipe' ? 800 : 0);
        if (isDataBaseFullConst || !db.objectStoreNames.contains(storeType === 'recipe' ? 'recipeImages' : 'userData')) {
            return false;
        }

        const transaction = db.transaction([storeType === 'recipe' ? 'recipeImages' : storeType === 'user' ? 'userData' : ''], 'readwrite');
        const store = transaction.objectStore(storeType === 'recipe' ? 'recipeImages' : 'userData'); // Simplified store selection

        const chunkSize = Math.ceil((395 * 1024) / 3) * 4;
        const regex = new RegExp(`[\\s\\S]{1,${chunkSize}}`, 'g');
        const chunks = base64String.match(regex);

        // Storing updatedAt as the first item
        const updatedAtItem = { id: recipeIdOrUserEmail + '-updatedAt', value: updatedAt };
        store.put(updatedAtItem);

        // Storing image chunks
        chunks?.forEach((chunk, id) => {
            const item = { id: recipeIdOrUserEmail + '-' + String(id).padStart(8, '0'), value: chunk };
            store.put(item);
        });

        return new Promise<boolean>((resolve) => {
            transaction.oncomplete = () => {
                db.close();
                resolve(true);
            };
            transaction.onerror = () => {
                db.close();
                resolve(false);
            };
        });
    } catch (error) {
        console.error('Error storing base64 string:', error);
        return false;
    }
}

export async function clearAllData(): Promise<boolean> {
    const storeTypes = ['recipe', 'user'];
    try {
        for (const storeType of storeTypes) {
            const dbRequest = window.indexedDB.open(storeType === 'recipe' ? 'recipe-client-database' : 'user-client-database');

            const db = await new Promise<IDBDatabase>((resolve, reject) => {
                dbRequest.addEventListener('success', (event: Event) => {
                    resolve((event.target as IDBOpenDBRequest).result as IDBDatabase);
                });

                dbRequest.addEventListener('error', (event: Event) => {
                    reject((event.target as IDBOpenDBRequest).error);
                });
            });

            // Check if the object store exists
            if (db.objectStoreNames.contains(storeType === 'recipe' ? 'recipeImages' : 'userData')) {

                const transaction = db.transaction(
                    [storeType === 'recipe' ? 'recipeImages' : storeType === 'user' ? 'userData' : ''],
                    'readwrite'
                );
                const store = transaction.objectStore(
                    storeType === 'recipe' ? 'recipeImages' : storeType === 'user' ? 'userData' : ''
                );
                const index = store.index('idIndex');
                const request = index.openCursor(IDBKeyRange.lowerBound(0));

                const deleteRequests: Promise<void>[] = [];

                request.onsuccess = (event) => {
                    const cursor = (event.target as IDBRequest).result as IDBCursorWithValue;
                    if (cursor) {
                        const deleteRequest = new Promise<void>((resolve, reject) => {
                            const deleteRequest = store.delete(cursor.primaryKey);
                            deleteRequest.onsuccess = () => resolve();
                            deleteRequest.onerror = (event) => reject((event.target as IDBRequest).error);
                        });

                        deleteRequests.push(deleteRequest);
                        cursor.continue();
                    }
                };

                await new Promise<void>((resolve, reject) => {
                    transaction.oncomplete = () => {
                        db.close();
                        resolve();
                    };
                    transaction.onerror = (event) => reject((event.target as IDBTransaction).error);
                });

                await Promise.all(deleteRequests);
            }

            db.close();
        }

        return true; // Data cleared successfully
    } catch (error) {
        console.error('Error clearing all data:', error);
        return false; // Error occurred during data clearing
    }
}

export async function deleteBase64StringsByRecipeIdOrEmail(storeType: string, recipeIdOrUserEmail: string): Promise<boolean> {
    try {
        // Check if the object store exists
        const db = await getDatabase(storeType, storeType === 'recipe' ? 'recipe-client-database' : 'user-client-database');
        const objectStoreName = storeType === 'recipe' ? 'recipeImages' : 'userData';
        if (!db.objectStoreNames.contains(objectStoreName)) {
            // Object store not found, return false
            db.close();
            return false;
        }

        // Check if the index exists
        const indexName = 'idIndex';
        const store = db.transaction([objectStoreName], 'readwrite').objectStore(objectStoreName);
        const indexNames = Array.from(store.indexNames);
        if (!indexNames.includes(indexName)) {
            // Index not found, return false
            db.close();
            return false;
        }

        // Index found, proceed with deleting the data
        const transaction = db.transaction([objectStoreName], 'readwrite');
        const index = store.index(indexName);
        const request = index.openCursor(IDBKeyRange.bound(recipeIdOrUserEmail + '-0', recipeIdOrUserEmail + '-updatedAt'));

        const deleteRequests: IDBRequest[] = []; // Store delete requests

        request.onsuccess = (event) => {
            const cursor = (event.target as IDBRequest).result as IDBCursorWithValue;
            if (cursor) {
                const deleteRequest = store.delete(cursor.primaryKey);
                deleteRequest.onsuccess = () => {
                    deleteRequests.push(deleteRequest);
                    cursor.continue();
                };
                deleteRequest.onerror = (event) => {
                    reject((event.target as IDBRequest).error);
                };
            } else {
                // Wait for all delete requests to complete
                Promise.all(deleteRequests.map((req) => new Promise((resolve) => req.addEventListener('success', resolve))))
                    .then(() => {
                        resolve(true); // Data deleted successfully (if there was any)
                    })
                    .catch((error) => reject(error));
            }
        };

        transaction.oncomplete = () => {
            db.close();
        };

        transaction.onerror = (event) => {
            reject((event.target as IDBTransaction).error);
        };

        return true; // Data deletion started successfully
    } catch (error) {
        console.error('Error deleting base64 strings:', error);
        return false; // Return false if there was an error (e.g., database not found)
    }
}

export async function getBase64StringByIdOrEmail(storeType: string, recipeIdOrUserEmail: string) {
    try {

        const objectStoreName = storeType === 'recipe' ? 'recipeImages' : 'userData';

        // Check if the object store exists
        const db = await getDatabase(
            storeType,
            storeType === 'recipe' ? 'recipe-client-database' : 'user-client-database'
        );
        if (!db.objectStoreNames.contains(objectStoreName)) {
            // Object store not found, return undefined
            db.close();
            return undefined;
        }

        const transaction = db.transaction([objectStoreName], 'readonly');
        const store = transaction.objectStore(objectStoreName);
        const index = store.index('idIndex');

        return new Promise<string | undefined>((resolve, reject) => {

            const request = index.openCursor(
                IDBKeyRange.bound(recipeIdOrUserEmail + '-0', recipeIdOrUserEmail + '-99999999')
            );

            const base64Chunks: { id: string; value: string }[] = [];

            request.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest).result;
                if (cursor) {
                    base64Chunks.push({ id: cursor.value.id, value: cursor.value.value });
                    cursor.continue();
                } else {

                    if (base64Chunks.length === 0) {
                        resolve(undefined);
                    } else {
                        base64Chunks.sort((a, b) => a.id.localeCompare(b.id));
                        const concatenatedString = base64Chunks.map((chunk) => chunk.value).join('');
                        resolve(concatenatedString);
                    }
                }
            };

            request.onerror = (event) => {
                console.error('Error opening cursor:', (event.target as IDBRequest).error); // Debug
                reject((event.target as IDBRequest).error);
            };

            transaction.oncomplete = () => {
                db.close();
            };

            transaction.onerror = (event) => {
                console.error('Transaction error:', (event.target as IDBTransaction).error); // Debug
                reject((event.target as IDBTransaction).error);
            };
        });
    } catch (error) {
        console.error('Error in getBase64StringByIdOrEmail:', error); // Debug
        return undefined;
    }
}

export async function getUpdatedAtImgStore(storeType: string, recipeIdOrUserEmail: string): Promise<string | undefined> {
    try {
        // Check if the object store exists
        const db = await getDatabase(storeType, storeType === 'recipe' ? 'recipe-client-database' : 'user-client-database');
        const objectStoreName = storeType === 'recipe' ? 'recipeImages' : 'userData';
        if (!db.objectStoreNames.contains(objectStoreName)) {
            // Object store not found, return undefined
            db.close();
            return undefined;
        }

        return new Promise<string | undefined>((resolve, reject) => {
            const transaction = db.transaction([storeType === 'recipe' ? 'recipeImages' : storeType === 'user' ? 'userData' : ''], 'readonly');
            const store = transaction.objectStore(storeType === 'recipe' ? 'recipeImages' : storeType === 'user' ? 'userData' : '');

            const key = recipeIdOrUserEmail + '-updatedAt';
            const request = store.get(key);

            request.onsuccess = (event) => {
                const result = (event.target as IDBRequest).result;
                if (result) {
                    resolve(result.value);
                } else {
                    resolve(undefined);
                }
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };

            transaction.oncomplete = () => {
                db.close();
            };

            transaction.onerror = (event) => {
                reject((event.target as IDBTransaction).error);
            };
        });

    } catch (error) {
        console.error('Error getting updatedAt from image store:', error);
        return undefined;
    }
}

export default {
    name: 'vueDynamoDBFunctions',
    methods: {
        getBase64StringByIdOrEmail,
        deleteBase64StringsByRecipeIdOrEmail,
        storeBase64String,
        getUpdatedAtImgStore,
        clearAllData
    },
};

function reject(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: DOMException | null
) {
    // throw new Error("Function not implemented." + error);
}


function resolve(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    arg0: boolean
) {
    // throw new Error("Function not implemented." + arg0);
}
